<template lang="pug">
q-page.overflow-hidden(style="margin-top: -35px")
  q-breadcrumbs.q-mt-xl.q-pt-lg.q-ma-dynamic(gutter="xs")
    template(v-slot:separator)
      q-icon(size="1.3em", :name="matChevronRight", color="primary")
    q-breadcrumbs-el.text-dark(:label="$t('home')", to="/")
    q-breadcrumbs-el.text-dark(:label="$t('academy.title')")
    q-breadcrumbs-el.text-primary(:label="$t('portfolio')")

  .row.justify-center.q-mt-xl(v-if="!pendingReview && !isComplete")
    .col-md-5.col-11
      .text-h1.text-primary Upload your portfolio of evidence below.
      .text-h4.q-mt-sm We will evaluate your portfolio and send you feedback or your certificate.

  .row.justify-center.q-mt-xl(v-if="!pendingReview && !isComplete")
    .col-11.col-md-5
      q-file.full-width(
        ref="formInput",
        v-model="file",
        outlined,
        :disable="disable",
        lazy-rules
      )
        template(v-slot:append)
          q-btn.bg-accent(
            flat,
            :icon="matUpload",
            dense,
            square,
            color="black",
            @click="getFile"
          )
      .text-center.q-pa-md
        q-spinner(v-if="disable", size="md", color="primary")
  .row.justify-center(v-if="!pendingReview && !isComplete")
    .col-11.col-md-5(v-if="reviews.length")
      .text-h6 Feedback
      q-list(separator)
        q-item(v-for="score,index of latestReview().scores", :key="index")
          q-item-section(avatar)
            q-icon(:name="matCheck", v-if="score === 1", color="primary")
            q-icon(:name="matCheck", v-if="score === 2", color="primary")
            q-icon(:name="matQuestion", v-if="score === 0", color="red")
          q-item-section
            .text-primary.text-bold {{ feedback[index][score] }}
            .text-small {{ criteria[index].title }}

    //- .col-5(v-if="doneupload")
    //-   .text-secondary We have recieved your portfolio and will get back to you.

  .row.justify-center.q-mt-xl(v-if="pendingReview")
    .col-11.col-md-5
      .text-h1.text-primary Pending Review...
      .text-h4.q-mt-sm Your portfolio is being reviewed, and feedback will be available when this is complete.

  .row.justify-center.q-mt-xl(v-if="isComplete")
    .col-md-5.col-11
      .text-h1.text-primary Congratulations!
      .text-h4.q-mt-sm You are a certified IFRC Innovation Faciliator

      q-btn.q-mt-xl(
        :href="downloadLink",
        target="_BLANK",
        size="lg",
        color="primary",
        no-caps,
        disable
      ) View Your Certificate
</template>

<script>
import { mapActions } from "vuex";
import { firebaseurl, auth } from "../db";
import { last, sortBy, every } from "lodash";
let axios;
export default {
  name: "Portfolio",
  data: () => {
    return {
      file: null,
      disable: false,
      doneupload: false,
      reviews: null,
      lastUpdated: null,
      criteria: [
        {
          title:
            "Evidence of taking part in the initial weeks of the programme and completing the practical activities including demonstrating they can identify suitable tools for a given scenario.",
        },
        {
          title:
            "Evidence of guiding groups to use techniques. Including introducing a method or giving guidance to groups about how to adapt a method to work in their context.",
        },
        {
          title:
            "Evidence of giving supportive feedback. Demonstrates giving feedback to someone but also some evidence of them acting on that feedback.",
        },
        {
          title:
            "Evidence of supporting your groups to sustain their involvement in the process. Did they encourage them to participate in an activity? Did they offer guidance and support to complete tasks?",
        },
        {
          title:
            "Evidence of using a variety of different techniques for facilitation. This might be through plans with different activities or it might be demonstrating they have supported them in a variety of ways.",
        },
      ],
      feedback: {
        0: {
          0: "Not evidenced",
          1: "Evidenced",
          2: "Great evidence",
        },
        1: {
          0: "Not evidenced",
          1: "Evidenced",
          2: "Great evidence",
        },
        2: {
          0: "Not evidenced",
          1: "Evidenced",
          2: "Great evidence",
        },
        3: {
          0: "Not evidenced",
          1: "Evidenced",
          2: "Great evidence",
        },
        4: {
          0: "Not evidenced",
          1: "Evidenced",
          2: "Great evidence",
        },
      },
    };
  },
  async mounted() {
    if (!axios) axios = await require("axios");
    const idToken = await auth.currentUser.getIdToken();
    const reviews = (
      await axios.get(
        `${firebaseurl}/submissions/reviews/${auth.currentUser.uid}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
    ).data;

    // console.log(reviews);

    this.lastUpdated = reviews.lastUpdated;
    this.reviews = reviews.reviews;
  },
  methods: {
    getFile() {
      this.$refs.formInput.$el.click();
    },
    latestReview() {
      // console.log(sortBy(this.reviews, "when"));
      return last(sortBy(this.reviews, "when"));
    },
    // getFeedback(index, score) {},
    ...mapActions(["uploadPortfolio"]),
  },
  computed: {
    pendingReview() {
      //if there is a submission
      if (this.lastUpdated) {
        // console.log(this.reviews);
        //if no reviews
        if (!this.reviews || this.reviews?.length === 0) return true;

        //old review
        // console.log(this.latestReview());
        if (this.latestReview().when < this.lastUpdated) return true;
      }

      return false;
    },
    isComplete() {
      //if no reviews:
      // if (this.reviews?.length === 0) return false;/

      //pending reviews:

      //incomplee

      if (this.latestReview()) {
        const win = every(this.latestReview().scores, (o) => o > 0);
        if (win) return true;
      }

      return false;
    },

    downloadLink() {
      return `/submissions/certificate/${auth.currentUser.uid}`;
    },
  },
  watch: {
    async file() {
      this.disable = true;
      // console.log(this.file)
      //do upload:
      await this.uploadPortfolio({ file: this.file });
      this.disable = false;
      // this.doneupload = true;
      this.lastUpdated = Date.now();
    },
  },
};
</script>